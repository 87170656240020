import React, { useEffect, useState } from "react";
import classes from "./school.module.css";
import { BrudCrumsNextIcon, EditPenIcon } from "../../../Assets/Icons/icons";
import { schoolDetails } from "../../../Utilities/TableDummyDatas";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { Card, Typography, Button, Tab, Tabs, Box } from "@mui/material";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import StatisticsCard from "../../../Components/statisticsCard";
import { SchoolDetailsPageStatisTicData } from "../../../Utilities/StatisticConstants";
import TeacherMannagementTab from "./teacherMannagementTab";
import {
  getOneSchools,
  getOneSchoolsPieChart,
} from "../../../Redux/actions/schoolActions";
import AlertBox from "../../../Components/AlertBox";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const SchoolDetailsViewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [value, setValue] = useState(0);
  const { oneSchool, error, oneSchoolPieChart } = useSelector(
    (state) => state.schoolDatas
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        index === value
          ? `${classes.selectedTab} ${classes.TabsLableHeader}`
          : classes.TabsLableHeader,
    };
  }

  const CloseAlert = () => {
    setErrorAlert(false);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const arr = path.split("/");
    dispatch(pageLoader(true));
    dispatch(getOneSchools(arr[arr.length - 1], setErrorAlert));
  }, []);

  useEffect(() => {
    if (oneSchool && oneSchool._id) {
      dispatch(
        getOneSchoolsPieChart(oneSchool?._id, setErrorAlert, oneSchoolPieChart)
      );
    }
  }, [value, oneSchool]);

  const handleNavigateSchool = () => {
    dispatch(setHeaderName("school"));
    navigate("/school");
  };

  const handleEditSchoolDetails = () => {
    dispatch(setHeaderName("edit-school-details"));
    navigate(`/edit-school-details/${oneSchool._id}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
console.log(oneSchool);

  return (
    <div>
      {oneSchool && (
        <div>
          <div className={`${classes.BrudcrumbContainer}`}>
            <div className={classes.brudcrumbs}>
              <span
                className={classes.TableHeaderText}
                onClick={() => handleNavigateSchool()}
              >
                School
              </span>
              <span>
                <BrudCrumsNextIcon />
              </span>
              <span className={classes.TableBodyText}>{oneSchool?.name}</span>
            </div>
            <div
              className={classes.brudcrumbs}
              onClick={() => {
                handleEditSchoolDetails(oneSchool);
              }}
            >
              <span>
                <EditPenIcon />
              </span>
              <span className={classes.TableBodyText}>Edit Details</span>
            </div>
          </div>
          <Card
            className="mt-4"
            style={{
              padding: "20px",
            }}
          >
            <Typography className={classes.SchoolsMainHeader}>
              School Details
            </Typography>

            <div className="mt-4">
              <Row>
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      School Name
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                    >
                      {oneSchool?.name}
                    </Typography>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      School Address
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                    >
                      {oneSchool?.address}
                    </Typography>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      School Contact Number
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                    >
                     + {oneSchool?.user?.country_code} {oneSchool?.user?.phone}
                    </Typography>
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      School Email ID
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                    >
                      {oneSchool?.user?.email}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Card
            className="mt-4"
            style={{
              padding: "20px",
            }}
          >
            <Typography className={classes.SchoolsMainHeader}>
              Subscription Details
            </Typography>
            <div className="mt-4">
              <Row>
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      Subscription Level
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                    >
                      {oneSchool?.subscription_info?.subscription_name}
                    </Typography>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-1">
                    <Typography
                      className={`${classes.TableBodyText} ${classes.viewFormLable} mb-1`}
                    >
                      Batch
                    </Typography>
                    <Typography
                      className={`${classes.MenuItemText} ${classes.viewFormValue}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {oneSchool?.batch.toLowerCase()}
                    </Typography>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
          <Box sx={{ width: "100%", marginTop: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "var(--forgot-password-text-color)",
                    height: "4px",
                  },
                }}
              >
                <Tab label="School Details" {...a11yProps(0)} />
                <Tab label="Teacher Management" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={value}
              index={0}
              className={`${classes.tabPanel} mb-5`}
            >
              <StatisticsCard data={oneSchoolPieChart} />
            </CustomTabPanel>
            <CustomTabPanel
              value={value}
              index={1}
              className={`${classes.tabPanel} mb-5`}
            >
              <TeacherMannagementTab oneSchool={oneSchool} />
            </CustomTabPanel>
          </Box>
        </div>
      )}
      {errorAlert && error ? (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      ) : null}
    </div>
  );
};

export default SchoolDetailsViewPage;
