import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import mainLogo from "../Assets/Images/MainLogo.png";
import {
  ChatIcon,
  ClassIcon,
  CourseIcon,
  DashboardIcon,
  LogoutIcon,
  PaymentIcon,
  ProfileNavIcon,
  RoleIcon,
  SchoolIcon,
  StudentIcon,
  SubscriptionIcon,
  TeacherIcon,
} from "../Assets/Icons/icons";
import profileIconImage from "../Assets/Images/Img_Avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Typography } from "@mui/material";
import {
  setHeaderName,
  setShowLogoutModal,
} from "../Redux/actions/userActions";
import { useEffect } from "react";
import SuccessModal from "./successModal";
import smallLogo from "../Assets/Images/favicon-512x512.png";
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';

const SideNavBar = ({ device, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleSideBar } = useSelector((state) => state.userLogin);
  const [logoutModal, setLogoutModal] = useState(false);
  const [currentRoute, setCurrentRoute] = useState();

  useEffect(() => {
    setCurrentRoute(window.location.pathname.toLowerCase().split("/")[1]);
  }, [window.location.pathname]);

  const handleNavigate = (name, route) => {
    if (name.includes("cources")) {
      dispatch(setHeaderName("Courses/Games"));
    } else {
      dispatch(setHeaderName(name));
    }
    setCurrentRoute(route);
    if (device === "mobile") {
      handleClose();
    }
  };

  const handleLogoutFunction = () => {
    dispatch(setShowLogoutModal(true));
    if (device === "mobile") {
      handleClose();
    }
  };

  return (
    <div>
      {toggleSideBar ? (
        <img
          src={mainLogo}
          alt="Queens Award Logo"
          className={`${classes.imageLogo} ${classes.sidenavLogoImage}`}
        />
      ) : (
        <img
          src={smallLogo}
          alt="Queens Award Logo"
          className={`${classes.smallImageLogo} ${classes.sidenavLogoImage}`}
        />
      )}
      <div className={`${classes.sidenavListMainCointainer}`}>
        <div
          className={`mt-2 ${classes.nabBarContainer}`}
          // style={{ minHeight: "750px" }}
        >
          <Link
            to="/dashboard"
            className={
              currentRoute?.includes("dashboard") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("dashboard", "/dashboard")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <DashboardIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Dashboard</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/school"
            className={
              currentRoute?.includes("school") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("school", "/school")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <SchoolIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>School</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/class-management"
            className={
              currentRoute?.includes("class") ? `${classes.activebtn}` : ``
            }
            onClick={() =>
              handleNavigate("class-management", "/class-management")
            }
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <ClassIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Class Management</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/students"
            className={
              currentRoute?.includes("student") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("students", "/students")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <StudentIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Student</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/teachers"
            className={
              currentRoute?.includes("teacher") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("teachers", "/teachers")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <TeacherIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Teachers</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/cources"
            className={
              currentRoute?.includes("cource")
                ? `${classes.activebtn}`
                : currentRoute?.includes("game")
                ? `${classes.activebtn}`
                : null
            }
            onClick={() => handleNavigate("cources", "/cources")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <CourseIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Courses/Games</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/role-management"
            className={
              currentRoute?.includes("role") ? `${classes.activebtn}` : ``
            }
            onClick={() =>
              handleNavigate("role-management", "/role-management")
            }
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <RoleIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Role Management</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/coupons"
            className={
              currentRoute?.includes("coupon") ? `${classes.activebtn}` : ``
            }
            onClick={() =>
              handleNavigate("coupon", "/coupons")
            }
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <DiscountOutlinedIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Coupon</span>
                ) : null}
              </Button>
            </li>
          </Link>
          {/* <Link
            to="/chat"
            className={
              currentRoute?.includes("chat") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("chat", "/chat")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <ChatIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Chat</span>
                ) : null}
              </Button>
            </li>
          </Link> */}
          <Link
            to="/payment"
            className={
              currentRoute?.includes("payment") ? `${classes.activebtn}` : ``
            }
            onClick={() => handleNavigate("payment", "/payment")}
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <PaymentIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>Payment</span>
                ) : null}
              </Button>
            </li>
          </Link>
          <Link
            to="/subscription-management"
            className={
              currentRoute?.includes("subscription")
                ? `${classes.activebtn}`
                : ``
            }
            onClick={() =>
              handleNavigate(
                "subscription-management",
                "/subscription-management"
              )
            }
          >
            <li>
              <Button variant="text" className={classes.linkburron}>
                <span
                  className={`${classes.activeIcon1} ${classes.navBarIcon}`}
                >
                  <SubscriptionIcon />
                </span>{" "}
                {toggleSideBar || device === "mobile" ? (
                  <span className={classes.sidebarText}>
                    Subscription Management
                  </span>
                ) : null}
              </Button>
            </li>
          </Link>
        </div>
        <div className={classes.ProfileManagementBtnsContainer}>
          <Button
            variant="text"
            className={classes.linkburron}
            onClick={() => {
              handleLogoutFunction();
            }}
          >
            <span className={`${classes.activeIcon1} ${classes.navBarIcon}`}>
              <LogoutIcon />
            </span>{" "}
            {toggleSideBar || device === "mobile" ? (
              <span className={classes.sidebarText}>Logout</span>
            ) : null}
          </Button>
          <Button
            variant="outlined"
            className={
              toggleSideBar || device === "mobile"
                ? `d-flex align-items-center justify-content-between ${classes.ProfileBtn}`
                : `d-flex align-items-center justify-content-between ${classes.ProfileBtnSmall}`
            }
          >
            <div className="d-flex align-items-center">
              <Avatar alt="Remy Sharp" src={profileIconImage} />
              {toggleSideBar || device === "mobile" ? (
                <Typography className={classes.ProfileAvatarBtnText}>
                  Admin
                </Typography>
              ) : null}
            </div>
            {toggleSideBar || device === "mobile" ? (
              <span>
                <ProfileNavIcon />
              </span>
            ) : null}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
