import React, { useEffect } from "react";
import classes from "./roleManagement.module.css";
import StatisticsCard from "../../../Components/statisticsCard";
import { RoleManagementStatisticData } from "../../../Utilities/StatisticConstants";
import {
  TextField,
  Typography,
  MenuItem,
  Button,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import RoleManagementTable from "./roleManagementTable";
import {
  otherTableData,
  MetaverseTableData,
  schoolTableData,
} from "../../../Utilities/TableDummyDatas";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader } from "../../../Redux/actions/userActions";
import { getRolesPiechart } from "../../../Redux/actions/roleManagementAction";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const RoleManagementIndex = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [errorAlert, setErrorAlert] = useState(false);
  const { piechartData } = useSelector((state) => state.roleDatas);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        index === value
          ? `${classes.selectedTab} ${classes.TabsLableHeader}`
          : classes.TabsLableHeader,
    };
  }

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getRolesPiechart(setErrorAlert, piechartData));
  }, [value, dispatch]);

  return (
    <div>
      <StatisticsCard data={piechartData} />
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className={classes.TabsHeaderDiv}
            TabIndicatorProps={{
              style: {
                backgroundColor: "var(--forgot-password-text-color)",
                height: "4px",
              },
            }}
          >
            <Tab label="Queens Award" {...a11yProps(0)} />
            <Tab label="Schools" {...a11yProps(1)} />
            <Tab label="Other" {...a11yProps(2)} />
          </Tabs> */}
        </Box>
        <RoleManagementTable
          value={value}
          data={schoolTableData}
          topic={"schools"}
        />
        {/* <CustomTabPanel
          value={value}
          index={0}
          className={`${classes.tabPanel} mb-5`}
        >
          <RoleManagementTable
            value={value}
            data={MetaverseTableData}
            topic={"Queens Award"}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          className={`${classes.tabPanel} mb-5`}
        >
          <RoleManagementTable
            value={value}
            data={schoolTableData}
            topic={"schools"}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
          className={`${classes.tabPanel} mb-5`}
        >
          <RoleManagementTable
            value={value}
            data={otherTableData}
            topic={"other"}
          />
        </CustomTabPanel> */}
      </Box>
    </div>
  );
};

export default RoleManagementIndex;
