import { DashboardMenuStatisticIcon, RoleManagementSchoolIcon, TotalTeachersStatIcon } from "../../Assets/Icons/icons";
import { ADD_ACCESS_LEVEL_FAIL, ADD_ACCESS_LEVEL_SUCCESS, ADD_ROLES_FAIL, ADD_ROLES_SUCCESS, GET_ALL_ACCESS_LEVEL_FAIL, GET_ALL_ACCESS_LEVEL_SUCCESS, GET_ALL_ROLES_FAIL, GET_ALL_ROLES_SUCCESS, GET_ONE_ROLE_FAIL, GET_ONE_ROLE_SUCCESS, SET_PIECHART_FAIL, SET_PIECHART_SUCCESS } from "../contants/roleManagementConstants";

const initialState = {
    error: null,
    success: null,
    rolesList: [],
    oneRole: null,
    totalCount: 0,
    accessLevelList: [],
    piechartData: [
        {
            icon: <TotalTeachersStatIcon />,
            title: "Queens Award",
            number: "0",
            key: "metaverse"

        },
        {
            icon: <RoleManagementSchoolIcon />,
            title: "Schools",
            number: "0",
            key: "schools"
        },
        {
            icon: <DashboardMenuStatisticIcon />,
            title: "Others",
            number: "0",
            key: "other"
        },
    ]
};

export const roleManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ACCESS_LEVEL_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case ADD_ACCESS_LEVEL_FAIL:
            return { ...state, error: action.payload };
        case GET_ALL_ACCESS_LEVEL_SUCCESS:
            return { ...state, error: null, accessLevelList: action.payload };
        case GET_ALL_ACCESS_LEVEL_FAIL:
            return { ...state, error: action.payload };
        case ADD_ROLES_FAIL:
            return { ...state, error: action.payload };
        case ADD_ROLES_SUCCESS:
            return { ...state, error: null, success: action.payload };
        case GET_ALL_ROLES_FAIL:
            return { ...state, error: action.payload };
        case GET_ALL_ROLES_SUCCESS:
            return { ...state, error: null, rolesList: action.payload.roles, totalCount: action.payload.totalCount };
        case GET_ONE_ROLE_FAIL:
            return { ...state, error: action.payload };
        case GET_ONE_ROLE_SUCCESS:
            return { ...state, error: null, oneRole: action.payload };
        case SET_PIECHART_SUCCESS:
            return { ...state, error: null, piechartData: action.payload };
        case SET_PIECHART_FAIL:
            return { ...state, error: action.payload };
        default:
            return state;
    }
};